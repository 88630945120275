<template>
  <section>
    <div class="col-md-12">
      <pm-Breadcrumb :home="home" :model="items_bread" class="hide-presenca" />
      <div class="hide-mob">
        <p style="text-align: center; font-size: 15px">
          <b v-for="b in items_bread">{{ b.label }} &nbsp</b>
        </p>
      </div>
      <br />
      <div class="form-elements">
        <div class="row">
          <div class="col-md-9 col-9">
            <div>
              <h2 class="titulo-links-escolha exibe-mobile-msg2">
                Presenças
                <va-popover
                  placement="right"
                  color="primary"
                  title="Informação do módulo atual"
                  message="Aqui o professor poderá lançar as presenças, faltas e justificativas de faltas de seus alunos!"
                >
                  <i
                    class="pi pi-question-circle"
                    style="font-size: 1.5rem"
                  ></i>
                </va-popover>
              </h2>
              <h2 class="titulo-links-escolha exibe-mobile-msg">Presenças</h2>
            </div>
          </div>

          <div class="col-md-3 col-3">
            <pm-Button
              type="button"
              style="float: right; margin-top: 5px"
              @click="trocarTurma()"
              label="Trocar de Turma"
              class="p-button-danger trocar-turma"
              icon="pi pi-angle-left"
              iconPos="left"
            ></pm-Button>
          </div>
        </div>
      </div>

      <p class="exibe-mobile-msg">
        Aqui o professor poderá lançar as presenças, faltas e justificativas de
        faltas de seus alunos!
      </p>
      <div class="p-fluid formgrid grid" v-if="!carregandoPagina">
        <div style="margin-top: 18px" class="field col-12">
          <div class="row col-md-12">
            <div
              :class="'col-md-' + sizeSelectDisciplinaCalendario.disciplinas"
            >
              <selecionarDisciplinasProfessor
                v-if="mostrarDisciplinas"
                @Config="Config"
                :disciplina="disciplina"
                :infoVerificarPresenca="infoVerificarPresenca"
              />
            </div>
            <div :class="'col-md-' + sizeSelectDisciplinaCalendario.calendario">
              <div
                v-if="disciplinaSelecionadaPeloProf != null && !isLoadingPage"
                style="background-color: white; border-radius: 5px"
              >
                <div
                  style="
                    background-color: rgb(255, 255, 255);
                    border-radius: 5px;
                    padding: 20px;
                  "
                  class="field col-12"
                >
                  <selectCalendario2
                    @exibirTabela="exibirTabela"
                    @test="test"
                    @abrirConteudo="abrirConteudo"
                    @abrirTodosOsConteudo="abrirTodosOsConteudo"
                    :mes_nome="this.mes_exib_selec"
                    :mes="this.mes_selec"
                    :tabelaDias="tabelaDias"
                    :mesNumero="mes_numeral"
                    :diaSelecionado="this.infoParaEnviar.dia"
                    @dialogAction="dialogAction"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <pm-Dialog
        header="Enviar presença"
        v-model:visible="displayMaximizable"
        :style="{ width: '100vw' }"
        :maximizable="true"
        :modal="true"
      >
        <div class="row">
          <div
            class="row field col-12"
            v-if="this.infoParaEnviar.dataSelecionada != ''"
          >
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-12" v-if="infoParaEnviar.dataSelecionada">
                  <h2 class="text-01-presenca">
                    Data selecionada: {{ infoParaEnviar.dataSelecionada }} - envio
                    de presença do dia para a disciplina de
                    {{ this.disciplinaSelecionadaPeloProf.disciplina }}
                  </h2>
                </div>
                <div
                  class="field col-12 md:col-3 lg:col-3"
                  v-if="presenca_infinita == 1"
                >
                  <label>Quantidade de presenças</label>
                  <select
                    class="p-inputtext p-component"
                    v-model="qdt_presenca"
                    style="appearance: revert !important; width: 100% !important"
                  >
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                  </select>
                </div>
              </div>
              <div class="p-fluid formgrid grid">
                <div class="row">
                  <div
                    class="row"
                    v-if="selectable && infoParaEnviar.dia"
                    style="margin-top: 5px"
                  >
                    <h4 class="title-presenca-table">Alunos da Turma</h4>
                    <table-prof-presenca01
                      @marcarPresenca="marcarPresenca"
                      :alunos="alunos"
                      :acesso="acesso"
                      :st_segmento="this.infoParaEnviar.segmento_id"
                      :etapaDeEnvioDePresenca="etapaDeEnvioDePresenca"
                    />
                  </div>

                  <div
                    class="row"
                    v-if="this.selectable && infoParaEnviar.dia"
                    style="left: 0px; width: 133px"
                  >
                    <va-inner-loading
                      class="py-3"
                      style="width: 100%; position: fixed; bottom: 0; right: 28px; display: grid; justify-content: end;"
                      :loading="isLoading"
                      v-if="acesso.cadastrar && this.alunos.length"
                    >
                      <button
                        class="btn btn-primary"
                        @click="presenca()"
                      >
                        Enviar Presença
                      </button>
                      <!--<button
                        class="btn btn-primary"
                        @click="presenca()"
                        v-if="conteudoObrigatorio == 1"
                      >
                        Enviar Presença
                      </button>
                      <button
                        class="btn btn-primary"
                        disabled 
                        v-else
                      >
                        Enviar Presença
                      </button>-->
                    </va-inner-loading>
                  </div>

                  <div
                    v-if="
                      editarOk &&
                        (this.segmento_tipo == 1 || this.serie_tipo == 1)
                    "
                  >
                    <editarFundamental1
                      @marcarPresenca="marcarPresenca"
                      :alunos="alunos"
                      @EditarPresencaDaTurma="EditarPresencaDaTurma"
                      @fecharEditarFundamentalUm="fecharEditarFundamentalUm"
                    />
                  </div>

                  <div
                    v-if="
                      editarOk &&
                        (this.segmento_tipo == 2 || this.serie_tipo == 2)
                    "
                  >
                    <editarFundamental2
                      @marcarPresencaFundamentalDois="
                        marcarPresencaFundamentalDois
                      "
                      :alunos="alunos"
                      @EditarPresencaDaTurma="EditarPresencaDaTurma"
                      @fecharEditarFundamentalUm="fecharEditarFundamentalUm"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <h3>Conteúdo Aplicado</h3>
              <modalConteudoAplicado
                edicao="0"
                :dados="{
                  disc_fantasia_id: 0,
                  disciplina_id: this.infoParaEnviar.disciplina_id,
                  data: this.infoParaEnviar.dataSelecionada,
                  turma_id: this.infoParaEnviar.turma_id,
                }"
                :mes_c="this.mes_c"
                :completo="0"
                @changeStatusModal="changeStatusModal"
                @saveAppliedContent ="saveAppliedContent"
                @conteudoObg ="conteudoObg"
              />
            </div>
          </div>
        </div>
        <template #footer> </template>
      </pm-Dialog>

      <pm-Dialog header="Conteúdo Aplicado" v-model:visible="displayModalConteudo" :style="{width: '100vw'}" :maximizable="true" :modal="true">
        <modalConteudoAplicado
          edicao="1"
          :dados="this.dados"
          :mes_c="this.mes_c"
          :completo="this.completo"
          @changeStatusModal="changeStatusModal"
          @saveAppliedContent ="saveAppliedContent"
          @closeContent="closeContent"
        />
      </pm-Dialog>

      <div
        v-if="carregandoPagina"
        class="col-md-12"
        style="text-align: center; padding-top: 255px"
      >
        <pm-ProgressSpinner />
      </div>

      <div>
        <div style="text-align: center">
          <pm-ProgressSpinner v-if="isLoadingPage" />
        </div>

        <div
          class="p-fluid formgrid grid"
          v-if="
            abrirComponete &&
              !isLoadingPage &&
              disciplinaSelecionadaPeloProf != null
          "
        >
          <div class="field col-12" style="margin-top: 20px">
            <CalendarioVisualizar
              :key="test"
              @test="test"
              @abrirConteudo="abrirConteudo"
              @abrirTodosOsConteudo="abrirTodosOsConteudo"
              :index="maiorIndex"
              :acesso="acesso"
              :presencasAlunosView="presencasAlunosView"
              :tabelaDias="tabelaDias"
              :mes_nome="this.mes_exib_selec"
              :mes_sel="this.mes_selec"
              :mes="mes_exib_selec"
              :disciplina_id="disciplinaSelecionadaPeloProf.disciplina_id"
              :turma_id = "turma_id"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import CalendarioVisualizar from "@/components/presenca/calendarioVisualizar.vue";

import tableProfPresenca01 from "@/components/presenca/tableProfPresenca01.vue";
import tableProfPresenca02 from "@/components/presenca/tableProfPresenca02.vue";
import selectCalendario from "@/components/presenca/selectCalendario.vue";
import selectCalendario2 from "@/components/presenca/selectCalendario2.vue";
import selecionarDisciplinasProfessor from "@/components/selecionarDisciplinasProfessor.vue";
import mostrarPresencaOuFalta from "@/components/presenca/mostrarPresencaOuFalta.vue";
import editarFundamental1 from "@/components/presenca/editarFundamental1.vue";
import editarFundamental2 from "@/components/presenca/editarFundamental2.vue";
import dataMatriculaAlunos from "@/utils/dataMatriculaAlunos.json";

import modalConteudoAplicado from "@/components/conteudo_aplicado/modal-conteudo-aplicado.vue";

import { PlanejamentoPedagogico } from "@/class/planejamentopedagogico";

import { defineComponent } from "vue";

import { Configuracao } from "@/class/configuracao";
import { Verificar } from "@/class/verificar.js";
import { Presenca } from "@/class/presenca";
import { Turma } from "@/class/turma";
import { Servidores } from "@/class/servidores";
import { SegmentoEscolar } from "@/class/segmentoEscolar.js";
import { SeriesEscolar } from "@/class/serie.js";
import { LiberacaoDiario } from "@/class/LiberacaoDiario.js";
import axios from "axios";

//import axios from 'axios'
//
export default defineComponent({
  props: {
    id: {
      required: true,
    },
    turma_id: {
      required: true,
    },
    professor_id: {
      required: true,
    },
    turma_disciplina_id: {
      required: true,
    },
    segmento: {
      required: true,
    },
    dataSelecionada: {
      required: true,
    },
    escola_id: {
      required: true,
    },
    serie_id: {
      required: true,
    },
    turno: {
      required: true,
    },
  },
  components: {
    CalendarioVisualizar,
    tableProfPresenca01,
    tableProfPresenca02,
    selectCalendario,
    selectCalendario2,
    selecionarDisciplinasProfessor,
    mostrarPresencaOuFalta,
    editarFundamental1,
    editarFundamental2,
    modalConteudoAplicado,
  },
  data() {
    return {
      carregandoPagina: false,
      dataSelecionaFormatoEUA: "",
      todosOsAlunos: [],
      abrirComponete: false,
      maiorIndex: 0,
      displayMaximizable: false,
      items: [
        {
          title: "Fevereiro",
          label: "fevereiro",
          to: "/ambiente-professor/presenca/fevereiro",
        },
        {
          title: "Março",
          label: "marco",
          to: "/ambiente-professor/presenca/marco",
        },
        {
          title: "Abril",
          label: "abril",
          to: "/ambiente-professor/presenca/abril",
        },
        {
          title: "Maio",
          label: "maio",
          to: "/ambiente-professor/presenca/maio",
        },
        {
          title: "junho",
          label: "junho",
          to: "/ambiente-professor/presenca/junho",
        },
        {
          title: "Julho",
          label: "julho",
          to: "/ambiente-professor/presenca/julho",
        },
        {
          title: "Agosto",
          label: "agosto",
          to: "/ambiente-professor/presenca/agosto",
        },
        {
          title: "Setembro",
          label: "setembro",
          to: "/ambiente-professor/presenca/setembro",
        },
        {
          title: "Outubro",
          label: "outubro",
          to: "/ambiente-professor/presenca/outubro",
        },
        {
          title: "Novembro",
          label: "novembro",
          to: "/ambiente-professor/presenca/novembro",
        },
        {
          title: "Dezembro",
          label: "dezembro",
          to: "/ambiente-professor/presenca/dezembro",
        },
      ],

      home: { icon: "pi pi-home", to: "/ambiente-professor/turmas-professor" },
      items_bread: [{ label: "" }],
      presencasAlunosView: [],
      isLoadingPageNotaTotal: false,
      isLoadingPage: false,
      desabilitarParaImprimirPdf: false,
      presencasDoAluno: [],
      editarOk: 0,
      porcentagemParaSerAprovado: 70,
      meses: [
        { numero: "01", palavra: "janeiro" },
        { numero: "02", palavra: "fevereiro" },
        { numero: "03", palavra: "marco" },
        { numero: "04", palavra: "abril" },
        { numero: "05", palavra: "maio" },
        { numero: "06", palavra: "junho" },
        { numero: "07", palavra: "julho" },
        { numero: "08", palavra: "agosto" },
        { numero: "09", palavra: "setembro" },
        { numero: "10", palavra: "outubro" },
        { numero: "11", palavra: "novembro" },
        { numero: "12", palavra: "dezembro" },
      ],
      ejaEhFundamental1: 2,
      apenasVisualizar: false,
      infoParaEnviar: {
        disciplina_id: "",
        mes: "",
        turma_id: "",
        segmento_id: "",
        ano: "",
        semana: "",
        dataSelecionada: "",
      },
      anoDeHoje: "",
      diaSelecionado: "",
      mesSelecionado: "",
      dataDeHoje: "",
      st_id: null,
      st_turma_id: null,
      st_professor_id: null,
      st_turma_disciplina_id: null,
      st_segmento: null,
      st_dataSelecionada: null,
      st_escola_id: null,
      st_serie_id: null,
      st_turno: null,
      turma_dados: [],
      isLoading: false,
      disciplinaSelecionadaPeloProf: null,
      alunos: [],
      alunosPresente: [],
      perPage: 5,
      currentPage: 1,
      selectable: true,
      tipo: 1,
      infoVerificarPresenca: {
        turma_id: this.st_turma_id,
        professor_id: this.st_professor_id,
        turma_disciplina_id: null,
        dia: null,
        mes: null,
        ano: null,
        disciplina_id: null,
        mes_numeral: 0,
      },
      verificadorDePresenca: [],
      etapaDeEnvioDePresenca: 0,
      disciplina: [],
      tabelaDias: [],
      tabelaDiasApenasAnosLetivoColunas: [],
      presenca_total: [],
      segmento_tipo: -1,
      serie_tipo: 0,

      mes_selec: "",
      mes_exib_selec: "",
      mese_numeral: "",
      sizeSelectDisciplinaCalendario: {
        disciplinas: 12,
        calendario: 12,
      },
      acesso: {
        visualizar: 1,
        cadastrar: 1,
        editar: 1,
        deletar: 1,
      },
      mostrarDisciplinas: true,
      mesAtual: "",
      autorizadoAMexer: false,
      mesesQuePodemSerAlterados: [],
      tabelaPresencasColunas: [],
      theAno: "",
      tipoDeRestricao: "Mensalmente",
      /**
       * indefinido
       * semanalmente
       * mensalmente
       */

      data_final_matricula: null,
      data_inicial_matricula: null,
      displayModalConteudo:false,
      funcaoDoUsuario: 0,
      presenca_infinita: 0,

      qdt_presenca: 1,
      dados:[],
      mes_c:"",
      completo:0,
      conteudoAplicado:[],
      conteudoObrigatorio:0,
      nVazio:[],
      nV:0
    };
  },
  computed: {
    pages() {
      return this.perPage && this.perPage !== "0"
        ? Math.ceil(this.alunos.length / this.perPage)
        : this.alunos.length;
    },
  },
  methods: {
    closeContent(n){
      if(n == 1){
        this.displayModalConteudo = false
      }
    },

    conteudoObg(valor){
      this.conteudoObrigatorio = valor
    },
    changeStatusModal(val){
      if(val == 1){
       this.displayModalConteudo = false
      }else{
        this.displayModalConteudo = true
      }
    },
    async abrirTodosOsConteudo(mes,it){
      console.log(it)
      this.mes_c = "";
      this.mes_c = mes;
      this.completo = 1;
      this.dados = it;
      this.displayModalConteudo = true;
    },
    async abrirConteudo(mes,it){
      //console.log(it)
      console.log(it)
      this.mes_c = "";
      this.mes_c = mes;
      this.completo = 0;
      this.dados = [];
      this.dados = it;
      this.displayModalConteudo = true;
    },
    async getQdtPresenca() {
      const data = await axios.get(
        "/config/sistema/presenca/" + this.st_segmento + "/segmento"
      );
      this.presenca_infinita = data.data.presenca_infinita;
    },
    getLastSaturday(referenceDate) {
      // Obtém o dia da semana da data de referência, onde 0 é Domingo e 6 é Sábado
      const dayOfWeek = referenceDate.getDay();

      // Calcula quantos dias devem ser subtraídos da data de referência para obter o último sábado
      // Se o dia da semana for Sábado, não precisa subtrair nenhum dia
      // Caso contrário, subtrai-se a diferença entre o dia da semana e 6, que é o índice do Sábado na semana
      const daysToSubtract = dayOfWeek === 6 ? 0 : (dayOfWeek + 1) % 7;

      // Cria uma nova data, subtraindo o número de dias calculado acima
      const lastSaturday = new Date(
        referenceDate.getTime() - daysToSubtract * 24 * 60 * 60 * 1000
      );

      // Obtém o ano, mês e dia da nova data e formata como uma string no formato "YYYY-MM-DD"
      const year = lastSaturday.getFullYear();
      const month = String(lastSaturday.getMonth() + 1).padStart(2, "0");
      const day = String(lastSaturday.getDate()).padStart(2, "0");
      const dateString = `${year}-${month}-${day}`;

      // Retorna a string formatada com a data do último sábado
      return dateString;
    },

    criarTabelaDePresencasParaCadaAluno(mapOfValues) {
      //Para exibir os dados na tabela de presenca
      for (var i = 0; i < this.presencasAlunosView.length; i++) {
        this.presencasAlunosView[i].presencas1 = [];
      }
      for (const dia of mapOfValues) {
        var presencasDoAluno = this.todasAsPresencasQueExistemNoDia(dia);
        for (var i = 0; i < this.presencasAlunosView.length; i++) {
          for (var j = 0; j < presencasDoAluno.length; j++) {
            //Verificar se o aluno i possue a preseja j
            const found = this.presencasAlunosView[i].presencas.find(
              (element) => element.data == presencasDoAluno[j].data
            );

            if (found != undefined) {
              this.presencasAlunosView[i].presencas1.push(found);
            } else {
              const aluno = {
                data: presencasDoAluno[j].data,
                disciplina_id: presencasDoAluno[j].disciplina_id,
                escola_id: presencasDoAluno[j].escola_id,
                segmento_id: presencasDoAluno[j].segmento_id,
                serie_id: presencasDoAluno[j].serie_id,
                professor_id: presencasDoAluno[j].professor_id,
                turma_id: presencasDoAluno[j].turma_id,
              };
              this.presencasAlunosView[i].presencas1.push(aluno);
            }
          }
        }
      }
    },
    //Receber todos os dia que possuem presencas
    receberTodosOsDiasQueExistemPresencas() {
      const myPresencasDias = new Set();
      for (let i = 0; i < this.presencasAlunosView.length; i++) {
        for (let j = 0; j < this.presencasAlunosView[i].presencas.length; j++) {
          if (
            !myPresencasDias.has(this.presencasAlunosView[i].presencas[j].dia)
          ) {
            myPresencasDias.add(this.presencasAlunosView[i].presencas[j].dia);
          }
        }
      }
      return Array.from(myPresencasDias).sort((a, b) => a - b);
    },
    //Buscar todas as presencas que existem em um dia X
    todasAsPresencasQueExistemNoDia(dia) {
      const mySet = new Set();
      const todasAsPresencasNoDia = [];
      for (let i = 0; i < this.presencasAlunosView.length; i++) {
        for (let j = 0; j < this.presencasAlunosView[i].presencas.length; j++) {
          if (
            !mySet.has(this.presencasAlunosView[i].presencas[j].data) &&
            dia == this.presencasAlunosView[i].presencas[j].dia
          ) {
            todasAsPresencasNoDia.push(
              this.presencasAlunosView[i].presencas[j]
            );
            mySet.add(this.presencasAlunosView[i].presencas[j].data);
            //Para add dias para criar as colunas da tabela
            this.tabelaPresencasColunas.push(dia);
          }
        }
      }
      return todasAsPresencasNoDia.sort(function (a, b) {
        return a.presenca_id - b.presenca_id;
      });
    },
    async mesesLiberados() {
      const novo = {
        modulo: "presencas",
        turma_id: this.st_turma_id,
        servidor_id: this.st_professor_id,
      };
      const data = await LiberacaoDiario.professorVerificarLiberacao(novo);
      this.mesesQuePodemSerAlterados = data.data;
    },
    async dialogAction() {
      //lancar presenca sem restricao
      if (this.tipoDeRestricao == "Indeterminado") {
      } //lancar presenca semanalmente
      else if (this.tipoDeRestricao == "Semanalmente") {
        const referenceDate = new Date();
        //receber o ultimo sabado.
        const dataUltimoSabado = this.getLastSaturday(referenceDate);
        if (this.dataSelecionaFormatoEUA <= dataUltimoSabado) {
          this.$vaToast.init({
            message:
              "Não é possível registrar presença para a data selecionada, pois o prazo para fazê-lo já expirou.",
            iconClass: "fa-star-o",
            position: "top-right",
            color: "warning",
            duration: 5000,
            fullWidth: false,
          });
          return false;
        }
      } //lancar presenca mensalmente
      else if (this.tipoDeRestricao == "Mensalmente") {
        //autorizado
        if (this.mes_numeral >= this.mesAtual) {
          //ok
        } else {
          this.mesesLiberados();
          //nao autorizado a mexer em meses anteriores
          const found = this.mesesQuePodemSerAlterados.find(
            (element) => element.mes_liberado == this.mes_selec
          );
          if (found == undefined) {
            this.$vaToast.init({
              message:
                "Não autorizado a alterar o mês selecionado! É necessário pedir uma autorização para realizar tal procedimento!",
              iconClass: "fa-star-o",
              position: "top-right",
              color: "warning",
              duration: 5000,
              fullWidth: false,
            });
            return false;
          }
        }
      }

      this.alunos = [];
      for (const el of this.todosOsAlunos) {
        let dataDeEntradaNaTurma = el.created_at.split("T");
        dataDeEntradaNaTurma = dataDeEntradaNaTurma[0].substring(0, 10);
        //Verificar se a data que o aluno entrou na turma é menor ou igual a data selecionada. E, se o aluno foi transferido, se a data que foi transferido já chegou. Se chegou nao pode mais lancar presenca
        if (
          dataDeEntradaNaTurma <= this.dataSelecionaFormatoEUA &&
          (el.saiu_da_turma_data == null ||
            this.dataSelecionaFormatoEUA < el.saiu_da_turma_data)
        ) {
          this.alunos.push(el);
        }
      }
      if (!this.alunos.length) {
        this.$vaToast.init({
          message: "A turma não tinha alunos na data selecionada!",
          iconClass: "fa-star-o",
          position: "top-right",
          color: "warning",
          duration: 3000,
          fullWidth: false,
        });
      } else {
        this.displayMaximizable = true;
      }
    },
    async test(mes, mesExibir) {
      this.isLoadingPage = true;

      this.mes_selec = mes;
      this.mes_exib_selec = mesExibir;
      //receber mês número
      const found = this.meses.find((element) => element.palavra == mes);
      this.mes_numeral = found.numero;

      this.mesSelecionado = mesExibir;

      this.tabelaDias = [];
      this.infoParaEnviar.dia = "";
      this.infoParaEnviar.semana = "";
      this.infoParaEnviar.dataSelecionada = "";

      let novo;
      if (this.infoParaEnviar.disciplina_id != 2) {
        novo = {
          disciplina_id: this.infoParaEnviar.disciplina_id,
          mes: mes,
          turma_id: this.infoParaEnviar.turma_id,
          segmento_id: this.infoParaEnviar.segmento_id,
          serie: this.infoParaEnviar.serie_id,
          ano: this.theAno,
        };
      } else {
        novo = {
          disciplina_id: 2,
          mes: mes,
          turma_id: this.infoParaEnviar.turma_id,
          segmento_id: this.infoParaEnviar.segmento_id,
          serie: this.infoParaEnviar.serie_id,
          ano: this.theAno,
        };
      }

      try {
        const data = await Presenca.presencaMontarCalendarioProfessor(novo);
        this.tabelaDias = data.data;
      } catch (e) {
        setTimeout(() => {
          this.$vaToast.init({
            message: e.response.data.erro,
            iconClass: "fa-star-o",
            position: "top-right",
            duration: 3500,
            fullWidth: false,
            color: "danger",
          });
        }, 500);
      }

      this.tabelaDiasApenasAnosLetivoColunas = [];
      //Apenas com dia letivo
      for (var i = 0; i < this.tabelaDias.length; i++) {
        if (this.tabelaDias[i].letivo == "0") {continue;}
        if (this.tabelaDias[i].dia.length == 1)
          {this.tabelaDias[i].dia = "0" + this.tabelaDias[i].dia;}
        this.tabelaDiasApenasAnosLetivoColunas.push(this.tabelaDias[i]);
      }

      let nv1;
      //presencas visu
      if (this.infoParaEnviar.disciplina_id != 2) {
        nv1 = {
          disciplina_id: this.infoParaEnviar.disciplina_id,
          mes: mes,
          turma_id: this.infoParaEnviar.turma_id,
          ano: this.theAno,
        };
      } else {
        nv1 = {
          disciplina_id: 2,
          mes: mes,
          turma_id: this.infoParaEnviar.turma_id,
          ano: this.theAno,
        };
      }

      const data1 = await Presenca.mesesVisu(nv1);
      this.presencasAlunosView = [];
      for (const el of data1.data) {
        const info = {
          id: el.aluno.id,
          nome: el.aluno.nome + " " + el.aluno.sobrenome,
          evento_saida: el.evento_saida,
          presencas: [],
          cntPresencas: 0,
          data_entrada_turma: el.data_entrada_na_turma,
          data_entrada_turma_br: el.data_entrada_na_turma_br,
          entrou_no_prazo_certo: el.entrou_no_prazo_certo,
          status: el.status,
          transferido: el.transferido,
          saiu_da_turma_data: el.saiu_da_turma_data,
          entrou_na_turma_por_transferencia:
            el.entrou_na_turma_por_transferencia,
          saiu_da_turma_data_formato_br: el.saiu_da_turma_data_formato_br,
          entradaESaidaDaTurma: el.entradaESaidaDaTurma,
          historico_saida_entrada_turma: el.historico_saida_entrada_turma,
          turma_id: el.turma_id,
        };
        const cntPresenca = 0;
        for (const el1 of el.presenca) {
          const novo = {
            presenca_id: el1.id,
            aluno_id: el.aluno.id,
            data: el1.data,
            disciplina_id: el1.disciplina_id,
            escola_id: el1.escola_id,
            id_presenca: el1.id,
            justificacao_conteudo: el1.justificacao_conteudo,
            justificada_falta: el1.justificada_falta,
            presenca: el1.presenca,
            professor_id: el1.professor_id,
            segmento_id: el1.segmento_id,
            serie_id: el1.serie_id,
            turma_id: el1.turma_id,
            dia: "",
          };
          //contar presencas
          info.cntPresencas += el1.presenca + el1.justificada_falta;
          //receber dia
          let receberDia = novo.data.split("-");
          receberDia = receberDia[2].split(" ");
          novo.dia = receberDia[0];
          info.presencas.push(novo);
        }
        this.presencasAlunosView.push(info);
      }
      let maior = 0;
      for (var i = 0; i < this.presencasAlunosView.length; i++) {
        if (this.presencasAlunosView[i].presencas.length > maior) {
          maior = this.presencasAlunosView[i].presencas.length;
          this.maiorIndex = i;
        }
      }
      this.tabelaPresencasColunas = [];
      this.criarTabelaDePresencasParaCadaAluno(
        this.receberTodosOsDiasQueExistemPresencas()
      );
      this.presencasAlunosView[0].tableColuna = this.tabelaPresencasColunas;

      setTimeout(() => {
        this.isLoadingPage = false;
      }, 100);

      this.abrirComponete = true;
      this.isLoadingPage = false;
      console.log(this.presencasAlunosView);
      //abrir modal
      return true;
    },

    async init() {
      try {
        // let calendario = await Calendario.calendarioAtual();

        if (
          this.segmento_tipo == 1 ||
          (this.segmento_tipo == 3 && this.serie_tipo == 1)
        ) {
          this.porcentagemParaSerAprovado = calendario.data.porcent_fund1_falta;
        } else {
          this.porcentagemParaSerAprovado =
            calendario.data.porcent_outros_falta;
        }
        this.anoDeHoje = this.theAno;
      } catch (e) {}
    },

    async disciplinasPresenca() {
      const novo = {
        turma_id: this.st_turma_id,
        professor_id: this.st_professor_id,
      };

      const data = await Turma.obtemTurmaProfessorV2(novo);
      this.disciplina = data.data;
    },

    async Config(disciplina, apenasUmaDisciplina) {
      this.isLoadingPage = true;
      if (apenasUmaDisciplina == false) {
        this.infoVerificarPresenca.disciplina_id = disciplina.disciplina_id;
        this.infoVerificarPresenca.turma_disciplina_id =
          disciplina.turma_disciplina_id;
        this.infoParaEnviar.disciplina_id = disciplina.disciplina_id;
        this.disciplinaSelecionadaPeloProf = disciplina;
      } else {
        this.disciplinaSelecionadaPeloProf = "1";
        this.infoVerificarPresenca.disciplina_id = "1";
        this.infoParaEnviar.disciplina_id = "1";
        this.disciplinaSelecionadaPeloProf = {
          disciplina: null,
          disciplina_id: null,
        };
        this.disciplinaSelecionadaPeloProf.disciplina = "Todas as matérias";
        this.disciplinaSelecionadaPeloProf.disciplina_id = "1";
      }
      this.infoParaEnviar.segmento_id = this.st_segmento;
      this.infoParaEnviar.turma_id = this.st_turma_id;
      this.infoParaEnviar.serie_id = this.st_serie_id;
      this.infoParaEnviar.ano = this.theAno;
      console.log(
        "DDDDDDDDDDDDDDDDDDDD",
        this.disciplinaSelecionadaPeloProf.disciplina_id
      );
      const date = new Date();
      const today = date.getDate();
      const currentMonth = date.getMonth() + 1;
      this.mesAtual = String(currentMonth);
      if (this.mesAtual.length == 1) {this.mesAtual = "0" + this.mesAtual;}
      if (currentMonth == 1) {
        await this.test("janeiro", "Janeiro");
      }
      if (currentMonth == 2) {
        await this.test("fevereiro", "Fevereiro");
      }
      if (currentMonth == 3) {
        await this.test("marco", "Março");
      }
      if (currentMonth == 4) {
        await this.test("abril", "Abril");
      }
      if (currentMonth == 5) {
        await this.test("maio", "Maio");
      }
      if (currentMonth == 6) {
        await this.test("junho", "Junho");
      }
      if (currentMonth == 7) {
        await this.test("julho", "Julho");
      }
      if (currentMonth == 8) {
        await this.test("agosto", "Agosto");
      }
      if (currentMonth == 9) {
        await this.test("setembro", "Setembro");
      }
      if (currentMonth == 10) {
        await this.test("outubro", "Outubro");
      }
      if (currentMonth == 11) {
        await this.test("novembro", "Novembro");
      }
      if (currentMonth == 12) {
        await this.test("dezembro", "Dezembro");
      }

      this.isLoadingPage = false;
    },

    trocarTurma() {
      this.$router.push({ name: "turmas-Professor" });
    },

    async exibirTabela(dia, semana, mes, mesLetra) {
      this.editarOk = false;
      this.infoParaEnviar.dia = dia;
      this.infoParaEnviar.mes = mesLetra;
      this.infoParaEnviar.semana = semana;

      if (String(dia).length == 1) {dia = "0" + dia;}

      this.infoParaEnviar.dataSelecionada =
        String(dia).padStart(2, "0") +
        "/" +
        mes.padStart(2, "0") +
        "/" +
        this.infoParaEnviar.ano;
      this.dataSelecionaFormatoEUA =
        this.infoParaEnviar.ano +
        "-" +
        mes +
        "-" +
        String(dia).padStart(2, "0");
    },

    marcarPresenca(n, item) {
      item.presente = n;
    },
    marcarPresencaFundamentalDois(chamada, n, item) {
      if (chamada == 1) {item.presenca_1 = n;}
      else {item.presenca_2 = n;}
    },

    async segmentoObtemUm(id) {
      const data = await SegmentoEscolar.obtemUm(id);
      this.segmento_tipo = data.data.tipo;
    },

    async serieObtemUm(id) {
      const data = await SeriesEscolar.obtemUm(id);
      if (this.segmento_tipo != 2) {
        this.serie_tipo = data.data.tipo;
      } else {
        this.serie_tipo = 0;
      }
    },

    async turmaAlunos(dataSelecionada) {
      this.todosOsAlunos = [];
      const data = await Turma.obtemAlunosTurma(this.st_turma_id);
      this.todosOsAlunos = data.data;
    },

    async saveAppliedContent(conteudo, envio){
      for (let i = 0; i < conteudo.length; i++) {
        if(conteudo[i].texto.length > 0 ){
          this.nVazio[i] = 1;
        }else{
          this.nVazio[i] = 0;
        }
      }

      let sum = 0;
      for (let i = 0; i < conteudo.length; i++) {
        sum+=this.nVazio[i];
      }
      
      if(sum == 0){
        sessionStorage.setItem("conteudoObrigatorio", 0);
        this.conteudoObg(0);
      }

      if(envio === 1){
        // salva o conteudo na Api
        for (let i = 0; i < conteudo.length; i++) {
          await PlanejamentoPedagogico.addPlanejamentoProf(conteudo[i]); 
        }
      }else{
        // guarda em variavel
        this.conteudoAplicado = [];
        this.conteudoAplicado = conteudo
      }
    },

    async presenca() {
      try {
        this.isLoading = true;
        this.alunosPresente = [];
        //array dos alunos
        for (let i = 0; i < this.alunos.length; i++) {
          if (this.alunos[i].presente == undefined) {
            alert("Adicione as informações de presença para todos os alunos!");
            return false;
          }
          const novo = {
            aluno_id: this.alunos[i].id,
            presenca: this.alunos[i].presente,
            justificada_falta: 0,
            justificacao_conteudo: "",
          };
          this.alunosPresente.push(novo);
        }

        //2022-05-26 10:38:11
        //Receber a hora atual
        const now = new Date();
        let horas = String(now.getHours());
        let minutos = String(now.getMinutes());
        let segundos = String(now.getSeconds());
        if (horas.length == 1) {horas = "0" + horas;}
        if (minutos.length == 1) {minutos = "0" + minutos;}
        if (segundos.length == 1) {segundos = "0" + segundos;}

        const horaAtual = horas + ":" + minutos + ":" + segundos;

        //Data selecionada pelo usuário
        const dataFormatoUSA = this.infoParaEnviar.dataSelecionada.split("/");
        const dataAtual =
          dataFormatoUSA[2] + "-" + dataFormatoUSA[1] + "-" + dataFormatoUSA[0];
        const dataEHora = dataAtual + " " + horaAtual;
        //let pf = await Servidores.buscarServidorIdLogin(this.st_professor_id);
        //informacoes importantes
        const info = {
          segmento_id: this.st_segmento,
          escola_id: this.st_escola_id,
          turma_id: this.st_turma_id,
          serie_id: this.st_serie_id,
          professor_id: this.st_professor_id,
          disciplina_id: this.disciplinaSelecionadaPeloProf.disciplina_id,
          data: dataEHora,
          qdt_presenca: this.qdt_presenca,
        };
        //fechar modal
        this.displayMaximizable = false;
        // const data = await Presenca.criarPresencaDosAlunos(
        //   this.alunosPresente,
        //   info
        // );
        try {
          await Presenca.criarPresencaDosAlunos(this.alunosPresente, info);
        } catch (error) {
            if (error.response && error.response.status === 429) {
                this.$vaToast.init({
                    message: 'Oopa, vamos com calma para não duplicar os lançamentos, aguarde 5 segundos e tente novamente.',
                    iconClass: "fa-star-o",
                    position: "bottom-left",
                    duration: 3500,
                    fullWidth: false,
                    color: "danger",
                });
            } else {
                alert("Erro ao enviar presença: Verifique se a data selecionada não é posterior à data atual!");
            }
            throw error;
        } 
        this.qdt_presenca = 1;
        this.$vaToast.init({
          message: "Presença enviada com sucesso!",
          iconClass: "fa-star-o",
          position: "top-right",
          color: "success",
          duration: 3000,
          fullWidth: false,
        });

        this.turmaAlunos();

        this.test(this.mes_selec, this.mes_exib_selec);
        this.saveAppliedContent(this.conteudoAplicado, 1)
        this.isLoading = false;
      } catch (e) {
        this.$vaToast.init({
          message: "Error ao enviar presença!",
          iconClass: "fa-star-o",
          position: "top-right",
          color: "danger",
          duration: 3000,
          fullWidth: false,
        });
        this.isLoading = false;
      }
    },

    fecharEditarFundamentalUm() {
      this.editarOk = !this.editarOk;
    },
    async EditarPresencaDaTurma(alunos) {
      try {
        this.alunosPresente = [];
        for (let i = 0; i < alunos.length; i++) {
          if (alunos[i].presente != undefined) {
            const novo = {
              aluno_id: alunos[i].aluno_id,
              id: alunos[i].id,
              nome: alunos[i].nome,
              turma_id: alunos[i].turma_id,
              presenca_1: alunos[i].presente,
              presenca_2: null,
              ano: this.infoParaEnviar.ano,
              mes: this.infoParaEnviar.mes,
              dia: this.infoParaEnviar.dia,
            };
            this.alunosPresente.push(novo);
          } else {
            const novo = {
              aluno_id: alunos[i].aluno_id,
              id: alunos[i].id,
              nome: alunos[i].nome,
              turma_id: alunos[i].turma_id,
              presenca_1: alunos[i].presenca_1,
              presenca_2: alunos[i].presenca_2,
              ano: this.theAno,
              mes: this.infoParaEnviar.mes,
              dia: this.infoParaEnviar.dia,
            };
            this.alunosPresente.push(novo);
          }
        }

        //Gerar presencas

        const pf = await Servidores.buscarServidorIdLogin(this.st_professor_id);
        const data = await Presenca.EditarPresencaDaTurma(
          this.alunosPresente,
          pf.data.id,
          this.disciplinaSelecionadaPeloProf.turma_disciplina_id,
          this.infoParaEnviar.turma_id,
          this.qdt_presenca
        );

        this.$vaToast.init({
          message: "Editado com sucesso!",
          iconClass: "fa-star-o",
          position: "top-right",
          color: "success",
          duration: 3000,
          fullWidth: false,
        });
        this.editarOk = false;
      } catch (e) {
        this.$vaToast.init({
          message: "Problema ao editar!",
          iconClass: "fa-star-o",
          position: "top-right",
          color: "danger",
          duration: 3000,
          fullWidth: false,
        });
      }
    },
  },
  async beforeMount() {
    this.funcaoDoUsuario = parseInt(sessionStorage.getItem("funcaoDoUsuario"));

    this.theAno = sessionStorage.getItem("anoSelecionado");

    this.carregandoPagina = true;
    if (this.id == null || this.turma_id == null) {
      this.st_segmento = sessionStorage.getItem("Professor_segmento");
      this.st_serie_id = sessionStorage.getItem("Professor_serie_id");
      await this.segmentoObtemUm(this.st_segmento);
      await this.serieObtemUm(this.st_serie_id);
      this.st_id = sessionStorage.getItem("Professor_id");
      this.st_turma_id = sessionStorage.getItem("Professor_turma_id");
      this.st_professor_id = sessionStorage.getItem("Professor_professor_id");
      this.st_turma_disciplina_id = sessionStorage.getItem(
        "Professor_turma_disciplina_id"
      );
      this.st_escola_id = sessionStorage.getItem("Professor_escola_id");
      this.st_turno = sessionStorage.getItem("Professor_turno");
      this.st_dataSelecionada = sessionStorage.getItem(
        "Professor_dataSelecionada"
      );
    } else {
      this.st_segmento = this.segmento;
      this.st_serie_id = this.serie_id;
      await this.segmentoObtemUm(this.st_segmento);
      await this.serieObtemUm(this.st_serie_id);
      this.st_id = this.id;
      this.st_turma_id = this.turma_id;
      this.st_professor_id = this.professor_id;
      this.st_turma_disciplina_id = this.turma_disciplina_id;
      this.st_escola_id = this.escola_id;
      this.st_turno = this.turno;
      this.st_dataSelecionada = this.dataSelecionada;
    }
    await this.init();

    const acesso = await Verificar.AcessoLocal(11);
    this.acesso.visualizar = acesso.visualizar;
    this.acesso.cadastrar = acesso.cadastrar;
    this.acesso.editar = acesso.editar;
    this.acesso.deletar = acesso.deletar;

    await this.disciplinasPresenca();

    await this.turmaAlunos();
    const turma = {
      turma_id: this.st_turma_id,
      etapa_id: this.st_segmento,
      periodo_id: this.st_serie_id,
      escola_id: this.st_escola_id,
    };
    const resp_turma = await Turma.aturma(turma);
    const tt = [
      {
        label: resp_turma.data[0].escola,
      },
      {
        label: resp_turma.data[0].etapa,
      },
      {
        label: resp_turma.data[0].periodo,
      },
      {
        label: resp_turma.data[0].turma,
      },
      {
        label: resp_turma.data[0].turno,
      },
    ];

    this.items_bread = tt;
    this.turma_dados = resp_turma.data;

    //tipo do eja
    const data2 = await SeriesEscolar.obtemUm(this.st_serie_id);
    //se for fundamental 1 e apenas um professor, selecionar a disciplna automaticamente
    if (
      (this.segmento_tipo == 1 && !data2.data.possui_mais_de_um_professor) ||
      (this.segmento_tipo == 3 &&
        data2.data.tipo == 1 &&
        !data2.data.possui_mais_de_um_professor)
    ) {
      this.mostrarDisciplinas = false;
      this.Config(null, true);
      this.sizeSelectDisciplinaCalendario.disciplinas = 0;
      this.sizeSelectDisciplinaCalendario.calendario = 12;
    } else {
      this.sizeSelectDisciplinaCalendario.disciplinas = 12;
      this.sizeSelectDisciplinaCalendario.calendario = 12;
    }
    await this.mesesLiberados();
    //dataMatriculaAlunos.forEach(x => { console.log(x); });

    this.carregandoPagina = false;

    await this.getQdtPresenca();

    //receber a configuracao do sistema
    const data4 = await Configuracao.buscarConfiguracaoDoSistama(turma);
    if (data4.data.fechamento == undefined)
      {this.tipoDeRestricao = "Mensalmente";}
    else {this.tipoDeRestricao = data4.data.fechamento;}
  },
});
</script>

<style lang="scss" scoped>
.table-example--pagination {
  padding-top: 20px;
  text-align: center;
  text-align: -webkit-center;
}
.cabecario-turma {
  background-color: #4da4e9;
  color: #fff;
  margin-right: 10px;
  padding: 8px;
  border-radius: 5px;
  text-transform: uppercase;
}
@media (max-width: 500px) {
  .titulo-links-escolha {
    font-size: 24px;
    margin-top: 12px;
  }
}
</style>

<style scoped>
.th_per1 {
  background-color: #f8f9fa !important;
  color: #495057 !important;
  border-right: 1px solid #e9ecef !important;
  border-bottom: 2px solid #e9ecef !important;
  text-align: center;
  padding: 1rem 1rem;
  font-weight: 600;
}
.th_per2 {
  background-color: #3f5287;
  color: #fff;
  border-right: 1px solid #fff;
  border-bottom: 2px solid #fff;
  text-align: center;
  font-weight: 500;
}
.th_pn_aluno {
  width: 15%;
}
@media (max-width: 720px) {
  .th_pn_aluno {
    width: 90%;
  }
}

@media (max-width: 500px) {
  .cabecario-turma {
    display: inline-grid;
    margin-bottom: 5px;
    font-size: 12px;
  }
}

.col-icon-univer {
  padding: 0px;
  padding-top: 0px;
  padding-left: 10px;
  width: 4%;
}
@media (max-width: 500px) {
  .col-icon-univer {
    width: 15%;
  }
  .col-cturm {
    width: 80%;
  }
}

.escolha-nome-disciplina {
  font-size: 22px;
  margin-top: 10px;
  padding-bottom: 15px;
}

@media (max-width: 500px) {
  .escolha-nome-disciplina {
    font-size: 16px;
  }
}

.semana {
  background-color: rgb(239 239 239);
  text-align: center;
}
.font-semana {
  font-size: 10px;
}
.dias {
  text-align: center;
}
.dia-marcado {
  background-color: #4182c5 !important;
  color: #fff;
}
</style>
